@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.inputWrapper {
  position: relative;
  width: 100%;
}

.label {
  position: absolute;
  color: var(--grey5);
  @include body3;
  transform-origin: top left;
  transform: translate(18px, 16px) scale(1);
  z-index: 2; // higher than the input
}

.select {
  input {
    height: 22px;
  }

  &:focus-visible {
    outline: 0;
  }

  :global {
    .select__control {
      background-color: var(--white);
      box-shadow: none;
      color: var(--grey5);
      @include body2;
      height: 78px;

      &.select__control--menu-is-open {
        border-color: var(--teal);
      }
      &:hover {
        border-color: var(--teal);
      }
    }

    .select__input-container {
      height: 22px;
      margin: 0;
    } // With search container
    .select__value-container {
      &:focus-visible {
        outline: 0;
      }
    }
    .select__indicator-separator {
      display: none;
    }
    .select__indicator {
      color: var(--navy);
    }
    .select__menu {
      z-index: 3; // higher than the absolute positioned label
      margin-top: 0;
      margin-bottom: 0;
      border-left: 1px solid var(--teal);
      border-bottom: 1px solid var(--teal);
      border-right: 1px solid var(--teal);
      box-shadow: none;
    }
    .select__option {
      padding: 16px;
      background-color: var(--white);
      border-bottom: 1px solid var(--grey3);
      &:last-child {
        border-bottom: none;
      }
      border-radius: 0;
      color: var(--navy);
      @include body2;
      overflow: hidden;
      &:active {
        background-color: var(--white);
        color: var(--teal);
        border-radius: 0;
      }
    }
    .select__option--is-focused {
      color: var(--teal);
    }
    .select__placeholder {
      color: var(--grey5);
    }
  }
}

.errorMessage {
  @include body3;
  color: var(--error);
}
