@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrapper {
  height: var(--navBarHeight);
  padding: 0 var(--padding);
  background-color: var(--grey3);

  &.isOpen {
    height: 100%;
    background-color: var(--zest3);

    &:before,
    &:after {
      content: unset;
    }
  }
}

.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--navBarHeight);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;

  button {
    padding: 0;
  }

  svg {
    width: auto;
    height: 26px;
    position: relative;

    path {
      fill: var(--navy);
    }
  }
}

.nav {
  height: 100%;
  margin-top: 24px;

  > ul {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
}

.navItem {
  width: 100%;
  margin-bottom: 16px;

  button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0;

    svg {
      margin-left: 16px;
    }
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-decoration: none;

    @include for-tablet-landscape {
      justify-content: flex-start;
    }
  }

  @include for-tablet-landscape {
    &:hover {
      background-color: inherit;
    }
  }
}

.fixedNavItem {
  position: absolute;
  left: var(--padding);
  right: var(--padding);
  bottom: 24px;

  @include for-tablet-landscape {
    a {
      justify-content: space-between;
      height: 64px;
      padding: 24px 20px;

      &:hover,
      &:active {
        gap: 20px;
        padding: 20px 16px 20px 20px;
      }
    }
  }
}

.dropdown {
  position: relative;
  width: 100%;
  z-index: var(--zTop);

  > ul {
    padding: 8px 16px 8px;
  }

  li + li {
    margin-top: 8px;
  }
}
