@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.inputWrapper {
  position: relative;
  width: 100%;
  // max-width: 432px;

  > button {
    position: absolute;
    right: 8px;
    top: 11px;
  }

  // Added for mini-calc, make local style if needed later
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input {
    appearance: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.label {
  position: absolute;
  color: var(--grey5);
  @include body3;
  transform-origin: top left;
  transform: translate(17px, 16px) scale(1);
}
.input {
  box-sizing: border-box;
  width: 100%;
  height: 78px;
  padding: 28px 16px;
  border: 1px solid var(--grey3);
  border-radius: 4px;
  background-color: var(--white);
  @include body2;

  &::placeholder,
  ::-webkit-input-placeholder,
  ::-moz-placeholder,
  :-moz-placeholder,
  :-ms-input-placeholder {
    color: var(--grey5);
    opacity: 1;
  }

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    outline: 0;
    border-color: var(--teal);
  }

  &.labelShown {
    padding: 40px 16px 16px 16px;
  }

  &.error {
    border: 1px solid var(--error);

    &::placeholder,
    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-moz-placeholder,
    :-ms-input-placeholder {
      color: var(--error);
      opacity: 1;
    }
  }

  &.disabled {
    border: 1px solid var(--grey3);
    background-color: var(--grey4);
    pointer-events: none;
    user-select: none;
    color: var(--grey5);
  }
}

.errorMessage {
  @include body3;
  color: var(--error);
}
