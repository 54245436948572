@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrap {
  position: relative;
  z-index: var(--zTop);
  color: var(--navy);
  height: var(--navBarHeight);
  width: 100%;
  max-width: $max-width-body;
  margin: auto;
  font-size: 16px;
  letter-spacing: -0.02rem;

  > div {
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;

    &::before,
    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      top: var(--navBarHeight);
      height: 48px;
      width: 24px;
      background-color: transparent;
      box-shadow: var(--grey3) 0 -24px 0 0;
    }

    &::before {
      left: var(--padding);
      border-top-left-radius: 24px;
    }

    &::after {
      right: var(--padding);
      border-top-right-radius: 24px;
    }

    @include for-mobile-screens {
      &::before,
      &::after {
        height: 24px;
        width: 12px;
        box-shadow: var(--grey3) 0 -12px 0 0;
      }

      &::before {
        left: var(--padding);
        border-top-left-radius: 12px;
      }

      &::after {
        right: var(--padding);
        border-top-right-radius: 12px;
      }
    }
  }
}
