@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrap {
  position: relative;
  z-index: 1;
  border-radius: 24px;
  background-color: var(--white);
  overflow: hidden;

  @include for-mobile-screens {
    border-radius: 12px;
  }

  &.components {
    border-radius: 0 0 24px 24px;
  }

  section:first-of-type:not(.components) {
    padding-top: var(--firstSectionPaddingTop);
  }

  > section:last-of-type {
    padding-bottom: var(--lastSectionPaddingBottom);
  }
}
