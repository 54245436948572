@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.overlay {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 24px;
  overflow: hidden;
  z-index: 1;
  opacity: 0;
}
