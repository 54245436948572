@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
$logoHeight: 144px;
$navColHeight: 283px;

.wrap {
  background-color: var(--grey3);
  position: relative;
  z-index: var(--zMid);

  @media (max-width: $breakpoint-tablet) {
    padding: var(--sectionPaddingLarge) var(--sectionPaddingSmall);
  }

  @media (min-width: $breakpoint-tablet) {
    padding: var(--sectionPaddingMedium) var(--sectionPaddingSmall);
  }

  @media (min-width: $standard-screen-min) {
    height: 797px;
  }
}

.columns {
  @media (max-width: $standard-screen-min) {
    display: block;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-of-type {
    @media (min-width: $breakpoint-tablet) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: auto;
    }
  }
}

.logo {
  height: $logoHeight;
  width: auto;
  max-width: 100%;

  @media (max-width: $standard-screen-min) {
    margin-bottom: 112px;
  }

  @media (min-width: $standard-screen-min) {
    margin-bottom: calc(#{$navColHeight} - #{$logoHeight});
  }
}

.privacyNav {
  @media (max-width: $breakpoint-tablet) {
    margin-bottom: 66px;
  }

  ul {
    display: flex;
    align-items: center;
    gap: calc(var(--padding) * 1.5);

    @media (max-width: $breakpoint-tablet) {
      flex-direction: column;
      align-items: flex-start;
      gap: calc(var(--padding) * 0.5);
    }
  }
}

.mainNav {
  display: grid;

  @media (max-width: $breakpoint-tablet) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 66px;
    margin: 66px 0;
  }

  @media (min-width: $breakpoint-tablet) and (max-width: $standard-screen-min) {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 66px;
    margin: 112px 0;
  }

  @media (min-width: $standard-screen-min) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.navCategory {
  display: inline-block;
  margin-bottom: var(--padding);
}

.navColumns {
  width: 100%;
  padding-right: 2em;

  @media (min-width: $standard-screen-min) {
    height: $navColHeight;
  }

  li {
    width: 100%;
    margin-bottom: calc(var(--padding) / 2);
    display: inline-block;
  }
}

.link {
  span {
    @media (hover: hover) {
      transition: color 0.2s;

      &:hover {
        color: var(--grey5);
      }
    }
  }
}

.colophon {
  svg {
    position: relative;

    @media (max-width: $breakpoint-tablet) {
      height: 26px;
      width: auto;
    }

    @media (min-width: $breakpoint-tablet) {
      height: 29px;
      width: auto;
    }
  }
}
