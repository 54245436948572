@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrap {
  width: 100%;
}

.formWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  input {
    width: 432px;
  }

  @include for-mobile-screens {
    flex-direction: column;
    width: 100%;

    input {
      width: 100%;
    }
  }
}

.inputWrapper {
  position: relative;
  margin: var(--padding) 0 calc(var(--padding) / 2);
  
  @include for-mobile-screens {
    width: 100%;
  }
}

.submitButton {
  position: absolute;
  top: 8px;
  right: 8px;
}

.formDisabled {
  pointer-events: none;
}

.errorWrap,
.successWrap {
  margin-top: 18px;
}

.hidden {
  display: none;
}

.privacyLink {
  position: relative;

  &:before, &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: currentColor;
    transition: transform 0.4s ease;
  }

  &:before {
    transform: scaleX(0);
    transform-origin: left;
  }
  
  &:after {
    transform: scaleX(1);
    transform-origin: right;
    transition-delay: 0.2s;
  }
  
  
  @media (hover: hover) {
    &:hover {
      &:before {
        transform: scaleX(1);
        transition-delay: 0.2s;
      }
      
      &:after {
        transform: scaleX(0);
        transition-delay: 0s;
      }
      
    }
  }
}