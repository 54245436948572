@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.hero {
  height: calc(100vh - var(--navBarHeight) - var(--padding) * 2);
  margin-bottom: var(--padding);

  @supports (height: 100svh) {
    height: calc(100svh - var(--navBarHeight) - var(--padding) * 2);
  }
}

.fixedWrap {
  position: fixed;
  max-width: calc(#{$max-width-body} - var(--padding) * 2);
  height: inherit;
  left: var(--padding);
  right: var(--padding);
  padding-top: var(--sectionPaddingSmall);
  padding-bottom: var(--sectionPaddingSmall);
  padding-left: inherit;
  padding-right: inherit;
  margin: auto;
  border-radius: 24px;
  overflow: hidden;

  @include for-mobile-screens {
    padding: calc(var(--padding) * 2);
    border-radius: 12px;
  }

  &.hidden {
    visibility: hidden;
  }
}

.videoPlayer,
.image {
  position: absolute;
  inset: 0;
}

.title {
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
}

.subtitle {
  white-space: pre-wrap;
  font-size: 24px;
  margin-bottom: 24px;
}

.columns {
  position: relative;
  z-index: 1;

  .column {
    display: flex;
    flex-direction: column;
 
    @include for-mobile-screens {
      gap: 24px;
    }

    @media (min-width: $ipad-min) and (max-width: $standard-screen-min) {
      grid-column-start: 1;
      grid-column-end: span 12;
    }
  }

}

.buttons {
  justify-content: initial;
  @include for-mobile-screens {
    flex-direction: column;
    position: absolute;
    bottom: 24px;
  }
}

.multipleButtons {
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
}

.titleColumn {
  justify-content: flex-end;
  margin-bottom: 48px;

  &.multiple{
    grid-column-start: 6;
    grid-column-end: span 6;
    margin-bottom: 24px;
  } 

}

.subtitleColumn {
  justify-content: flex-start;
  gap: 24px;

  &.multiple{
    grid-column-start: 6;
    grid-column-end: span 6;
  } 

}


