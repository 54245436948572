@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.wrapper {
  width: 100%;
  max-width: $max-width-body;
  background-color: var(--grey3);
}

.navBar {
  display: flex;
  align-items: center;
  padding: calc(var(--padding) / 2) var(--padding);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    height: 26px;
    width: auto;

    path {
      fill: currentColor;
    }
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  height: 100%;
  margin-left: calc(var(--padding) * 3.5);

  > ul {
    display: flex;
    gap: 12px;
    align-items: center;
    height: 100%;
  }
}

.navItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--navLinkHeight);
}

.fixedNavItem {
  padding: 12px;
  height: auto;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 14px;
  border-radius: 4px;
  transition: background-color 0.15s ease-in;

  &.bgYellow {
    background-color: var(--zest3);
  }

  &.active {
    &.bgYellow {
      background-color: var(--zest2);
    }

    &:not(.bgYellow) {
      background-color: var(--grey2);
    }
  }

  span {
    transition: color 0.15s ease-in;
  }

  .arrowRight {
    margin-left: 5px;
    transform: translateX(0);
    transition: transform 0.3s ease-in;
  }

  &:hover {
    &.bgYellow {
      background-color: var(--zest4);
    }

    &:not(.bgYellow) {
      span {
        color: var(--grey5);
      }
    }

    .arrowRight {
      transform: translateX(4px);
    }
  }
}

.buttonsWrapper {
  display: flex;
  gap: 8px;
}

.dropdown {
  background-color: var(--grey2);
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  width: 356px;
  z-index: var(--zTop);
  border-radius: 4px;
  cursor: default;

  ul {
    padding: 22px;
  }

  li {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }

  li + li {
    margin-top: 12px;
  }

  li > a {
    position: relative;
    width: 100%;

    &:hover {
      color: var(--grey5);
    }
  }
}
