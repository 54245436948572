@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.navButton {
  width: 48px;
  height: 48px;
  padding: 14px;
  position: relative;
  transform: rotate(0);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.navButton span {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  width: 20px;
  margin: auto;
  opacity: 1;
  background-color: var(--navy);
  transform: rotate(0);
  transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out;
}

.navButton span:nth-of-type(1) {
  top: 15px;
}

.navButton span:nth-of-type(2),
.navButton span:nth-of-type(3) {
  top: 0;
  bottom: 0;
}

.navButton span:nth-of-type(4) {
  top: 31px;
}

.navButton.isOpen span:nth-of-type(1) {
  opacity: 0;
  transition: transform 0.35s ease-in-out, opacity 0s;
}

.navButton.isOpen span:nth-of-type(2) {
  transform: rotate(45deg);
}

.navButton.isOpen span:nth-of-type(3) {
  transform: rotate(-45deg);
}

.navButton.isOpen span:nth-of-type(4) {
  opacity: 0;
  transition: transform 0.35s ease-in-out, opacity 0s;
}

.dropdown {
  overflow: hidden;
}
