@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.root {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--navy);
  @include body2();
}

.checkbox {
  cursor: pointer;
  appearance: none;
  height: 20px;
  width: 20px;
  border: 1px solid var(--grey3);
  border-radius: 4px;

  margin-right: 14px;
  position: relative;

  transition: 0.2s all linear;
  &:hover {
    border: 1px solid var(--teal);
  }
  &:checked {
    + .check {
      opacity: 1;
    }
  }
}

.check {
  position: absolute;
  transform-origin: top left;
  transform: translate(6px, 0) scale(1);
  opacity: 0;
  transition: 0.2s all linear;
}
