@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.icon {
  transition: all 0.2s ease;
  &.inverted {
    transform: rotate(180deg);
  }
  margin-left: 2px;
}

.toggleWrapper {
  position: relative;
}

.selectButton {
  display: flex;
  justify-content: flex-end;
  min-width: 70px;
}
.dropdown {
  background-color: var(--grey2);
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: calc(100% + 8px);
  overflow: hidden;
  z-index: var(--zTop);
  border-radius: 16px;

  cursor: default;
  color: var(--navy);

  ul {
    padding: 20px 16px;
  }

  li {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }

  li + li {
    margin-top: 12px;
  }

  li > * {
    position: relative;
  }
}

.label {
  text-align: start;
  white-space: nowrap;

  &:hover {
    color: var(--grey5);
  }
}
